<template>
  <div class=" mb-4">
    <p class="text-left m-0">
      <span class="h5 text-main fw-bold align-text-bottom">Sığorta məhsulları</span>
    </p>
    <div class="row px-2">
      <router-link
      to="/profile/ins/car"
        class="
          col-xs-12 col-sm-6 col-md-12 col-lg-3 col-xl-3
          mt-3
          py-4
          me-2
          insurance-card
          text-center
          text-decoration-none
        "
      >
        <img src="@/assets/images/car.svg" />
        <span class="ps-2">Avto İcbari</span>
      </router-link>
      <router-link
        to="/profile/ins/travel"
        class="
          col-xs-12 col-sm-6 col-md-12 col-lg-3 col-xl-3
          mt-3
          py-4
          me-2
          insurance-card
          text-center
          text-decoration-none
        "
      >
        <img src="@/assets/images/plane.svg" />
        <span class="ps-2">Səfər</span>
      </router-link>
    </div>
  </div>
</template>